import { Button, Code, Divider, Flex, Text, Title } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  let error = useRouteError();
  // Print the error message to trace
  console.error(error);

  useEffect(() => {
    if (Sentry.isInitialized()) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <>
      <div className="flex flex-col gap-6 bg-[#f5f5f5] w-1/2 justify-center mt-12 mx-auto">
        <Title>Đã có lỗi xảy ra.</Title>
        <Text>
          Chúng tôi đã gặp phải vấn đề trong quá trình thiết lập. Nội dung lỗi
          đã được gửi tới quản trị viên.
        </Text>
        <Divider />

        <Text fw={"bold"} size="lg">
          {error && (error as any).message}
        </Text>

        {error !== undefined && (
          <Code className="min-h-[30vh] max-h-[30vh]">
            {(error as any).stack}
          </Code>
        )}
        <Flex direction={"row"}>
          <Button
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Tải lại trang chủ
          </Button>
        </Flex>
      </div>
    </>
  );
}
