import { ActionIcon, Affix, Image, Transition } from "@mantine/core";
import { HiPhone } from "react-icons/hi2";
import ContactInformation from "../../constants/contact-information";
import MessengerIcon from "/messenger.svg";
import ZaloIcon from "/zalo.svg";

const AFFIX_ICON_SIZE = 48;

export default function MainAffix() {
  return (
    <Affix position={{ bottom: 20, right: 20 }}>
      <Transition transition="slide-up" mounted={true}>
        {(transitionStyles) => (
          <div className="flex flex-col gap-3">
            <ActionIcon
              className="border border-solid"
              bg={"green"}
              c={"white"}
              style={transitionStyles}
              variant="white"
              w={AFFIX_ICON_SIZE}
              h={AFFIX_ICON_SIZE}
              component="a"
              href={ContactInformation.tel}
            >
              <HiPhone size={AFFIX_ICON_SIZE - 16} />
            </ActionIcon>
            <ActionIcon
              // leftSection={<IconArrowUp style={{ width: rem(16), height: rem(16) }} />}
              style={transitionStyles}
              variant="white"
              w={AFFIX_ICON_SIZE}
              h={AFFIX_ICON_SIZE}
              component="a"
              href={ContactInformation.messenger}
            >
              <Image
                src={MessengerIcon}
                w={AFFIX_ICON_SIZE - 8}
                h={AFFIX_ICON_SIZE - 8}
              />
            </ActionIcon>
            <ActionIcon
              // leftSection={<IconArrowUp style={{ width: rem(16), height: rem(16) }} />}
              style={transitionStyles}
              variant="white"
              w={AFFIX_ICON_SIZE}
              h={AFFIX_ICON_SIZE}
              component="a"
              href={ContactInformation.zalo}
            >
              <Image
                src={ZaloIcon}
                w={AFFIX_ICON_SIZE - 8}
                h={AFFIX_ICON_SIZE - 8}
              />
            </ActionIcon>
          </div>
        )}
      </Transition>
    </Affix>
  );
}
