import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/layout/Layout";
import ErrorPage from "./pages/error";
import { urlify } from "./utils/formatter";

const MainPage = lazy(() => import("./pages/main/MainPage"));
const Products = lazy(() => import("./pages/products/Products"));
const MainProduct = lazy(() => import("./pages/product/MainProduct"));
const MainCheckout = lazy(() => import("./pages/checkout/MainCheckout"));
const CompleteCheckout = lazy(
  () => import("./pages/complete-checkout/CompleteCheckout")
);
const MainContact = lazy(() => import("./pages/contact/MainContact"));
const MainAbout = lazy(() => import("./pages/about/MainAbout"));
const MainPrivatePolicy = lazy(() => import("./pages/private-policy/index"));
const MainNotFound = lazy(() => import("./pages/notFound/MainNotFound"));

export const RoutePaths = {
  Main: "/",
  Category: "/category",
  Product: `/product`,
  /**
   * @deprecated
   */
  Products: `/product`,
  Checkout: `/checkout`,
  CompleteCheckout: `/complete-checkout`,
  Contact: "/contact",
  About: "/about",
  PrivatePolicy: "/private-policy",
};
const router = createBrowserRouter([
  {
    path: RoutePaths.Main,
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <MainPage />,
      },
      {
        path: urlify(RoutePaths.Products),
        element: <MainProduct />,
      },
      {
        path: urlify(RoutePaths.Product),
        children: [
          {
            index: true,
            element: <Products />,
          },
          {
            path: ":productId",
            element: <MainProduct />,
          },
        ],
      },
      {
        path: RoutePaths.Checkout,
        element: <MainCheckout />,
      },
      {
        path: RoutePaths.CompleteCheckout,
        element: <CompleteCheckout />,
      },
      {
        path: RoutePaths.Contact,
        element: <MainContact />,
      },
      {
        path: RoutePaths.About,
        element: <MainAbout />,
      },
      {
        path: RoutePaths.PrivatePolicy,
        element: <MainPrivatePolicy />,
      },
      {
        path: "*",
        element: <MainNotFound />,
      },
    ],
  },
]);

const MainRouter = router;
export default MainRouter;
