import { MantineProvider, MantineTheme } from "@mantine/core";
import "@mantine/core/styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useState } from "react";

import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import CartDrawerStateContext from "./context/CartDrawerStateContext.tsx";
import "./index.css";
import MainRouter from "./router.tsx";

const client = new QueryClient();

const theme: Partial<MantineTheme> = {
  defaultRadius: "xl",
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  primaryColor: "orange",
};

function App() {
  const [isOpenCartDrawer, setOpenCartDrawer] = useState<boolean>(false);
  return (
    <React.StrictMode>
      <MantineProvider theme={theme}>
        <QueryClientProvider client={client}>
          <CartDrawerStateContext.Provider
            value={{ isOpen: isOpenCartDrawer, setOpen: setOpenCartDrawer }}
          >
            <RouterProvider router={MainRouter} />
            <Toaster position="bottom-center" reverseOrder={false} />
          </CartDrawerStateContext.Provider>
        </QueryClientProvider>
      </MantineProvider>
    </React.StrictMode>
  );
}

export default App;
