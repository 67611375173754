import { Skeleton } from "@mantine/core";
import { FC, Suspense, lazy } from "react";
import { Outlet } from "react-router-dom";
import MainAffix from "../affix/MainAffix";
const Footer = lazy(() => import("../footer"));

const Navbar = lazy(() => import("./../navbar/Navbar"));

const FallbackNavbar: FC = () => {
  return (
    <div className="flex flex-row gap-4">
      <Skeleton height={32} w={32} />
      <Skeleton height={32} w={64} />
      <Skeleton height={32} w={64} className="flex-1" />
      <Skeleton height={32} w={32} />
    </div>
  );
};

const FallbackBody: FC = () => {
  return (
    <div className="mx-4">
      <Skeleton mt={12} h={32} />
      <Skeleton mt={12} h={32} />
      <Skeleton mt={12} h={32} />
      <Skeleton mt={12} h={32} />
    </div>
  );
};

export default function Layout() {
  return (
    <>
      {/* Navbar */}

      <Suspense
        fallback={
          <div className="navbar-wrapper px-4 py-4 border-b border-solid bg-neutral-50 sticky top-0 z-[50]">
            <FallbackNavbar />
          </div>
        }
      >
        <Navbar />
      </Suspense>

      {/* Body */}
      <>
        <Suspense fallback={<FallbackBody />}>
          <Outlet />
        </Suspense>
      </>

      {/* Footer */}
      <>
        <Suspense fallback={<FallbackBody />}>
          <Footer />
        </Suspense>
      </>

      {/* Affix contacts */}
      <>
        <Suspense fallback={<FallbackBody />}>
          <MainAffix />
        </Suspense>
      </>
    </>
  );
}
