export function enhanceCurrency(x: number) {
  return String(x)
    .split(".")[0]
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function urlify(...args: string[]) {
  return args.join("/");
}

/**
 * Transforms thousand value as decimal.
 * Examples: 1234 -> 1,2k ...
 *
 * @param num a real number to transform into k
 * @param digits a digits after decimal point
 * @returns return a decimal after thousand.
 */
export function thousandTransform(num: number, digits: number = 1) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  // Regular expression without lookbehind to remove trailing zeros
  // - supported old Safari versions
  const regexp = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find((item) => num >= item.value);
  return item
    ? (num / item.value).toFixed(digits).replace(regexp, (_, p1) => p1 || "").concat(item.symbol)
    : "0";
}
